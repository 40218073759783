import { useWindowSize } from 'usehooks-ts'
import React from 'react'
import styled from 'styled-components'

import { BlockKeys } from '@/utils/constants'
import { GenerateWagtailBlocks } from '@/components/GenerateWagtailBlocks'
import { GenericLink } from '@/components/commons/GenericLink'
import { GenericList } from '@/components/GenericList'

import type { Block, TeamMemberBlock, TeamsMembersBlock } from '@/models/Blocks'

export const TeamsMembers = ({
  block,
  id,
  pagePrefix
}: {
  block: TeamsMembersBlock
  id?: string
  pagePrefix: string
}) => {
  const { width } = useWindowSize()

  if (!block || !block.value) return null

  const { isSlider, isSliderOnMobile, members, link } = block.value

  const displayAsSlider = isSlider || (width < 768 && isSliderOnMobile)

  const mappedTeamsMembers: TeamMemberBlock[] = members.map((member, i) => ({
    type: BlockKeys.teammember,
    id: `teammember-${id}-${i}`,
    value: member
  }))

  return (
    <Wrapper id={id}>
      <GenericList
        list={mappedTeamsMembers as Block[]}
        isSlider={displayAsSlider}
        sliderOptions={{
          loop: true,
          mode: 'snap',
          breakpoints: {
            '(min-width: 0px)': {
              slides: { origin: 'center', perView: 1.1, spacing: 30 }
            },
            '(min-width: 768px)': {
              slides: {
                perView: 2,
                spacing: 40
              }
            },
            '(min-width: 1000px)': {
              slides: {
                perView: 3,
                spacing: 40
              }
            },
            '(min-width: 1300px)': {
              slides: {
                perView: 4,
                spacing: 40
              }
            }
          }
        }}
        pagePrefix={pagePrefix}
        areArrowsVisible
      >
        <Grid>
          <GenerateWagtailBlocks
            blocks={mappedTeamsMembers as Block[]}
            prefix={`${pagePrefix}-grid`}
          />
        </Grid>
      </GenericList>
      {link && (
        <LinkWrapper>
          <GenericLink wagtailLink={link} role={'link'} showDecorator />
        </LinkWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 90px;
  padding: 0 160px 110px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    gap: 30px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.secondary[200]};
    display: block;
    text-align: center;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
`
