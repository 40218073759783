import React from 'react'
import styled from 'styled-components'

import { BlockKeys } from '@/utils/constants'
import { CallToAction } from '@/components/blocks/CTA'
import { Product } from '@/components/blocks/Product'

import type { ProductsBlock } from '@/models/Blocks'

export const Products = ({
  block,
  id
}: {
  block: ProductsBlock
  id?: string
}) => {
  if (!block || !block.value || !block.value.body) return null

  const { body } = block.value

  return (
    <List id={id} columnsNumber={2}>
      {body.map((block, i) =>
        BlockKeys.product === block.type ? (
          <Product key={i} block={block} />
        ) : (
          <CustomCTA key={i} block={block} id={id} isSection />
        )
      )}
    </List>
  )
}

const List = styled.div<{ columnsNumber: number }>`
  display: grid;
  grid-template-columns: ${({ columnsNumber }) =>
    `repeat(${columnsNumber}, minmax(0, 1fr))`};
  padding: 80px 160px 100px 160px;
  column-gap: 40px;
  row-gap: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    grid-template-columns: 1fr;
    padding: 50px 20px;
    gap: 50px;
  }
`

const CustomCTA = styled(CallToAction)`
  margin: 0;
  padding: 0;
  flex-direction: column !important;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    min-height: 400px;
  }
`
