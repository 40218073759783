import { RenditionImage } from '@/components/commons/RenditionImage'
import Link from 'next/link'
import React from 'react'

import { GenericLink } from '@/components/commons/GenericLink'
import { H2, H4, Text } from '@/components/commons/Typography'
import { Position } from '@/components/commons/Position'
import styled from 'styled-components'

import type { CaseStudyBlock } from '@/models/Blocks'

export const CaseStudy = ({
  block,
  id
}: {
  block: CaseStudyBlock
  id: string
}) => {
  if (!block || !block.value) return null

  const {
    abstract,
    clientName,
    detailPagePath,
    indexPagePath,
    options,
    position,
    productName
  } = block.value

  return (
    <Wrapper id={id}>
      <Content>
        <TextWrapper>
          <H2.Normal>
            Case
            <br />
            studies
          </H2.Normal>
        </TextWrapper>
        <Case href={detailPagePath}>
          <RenditionImage image={options[0].value} />
          <ClientProduct>{[productName, clientName].join('|')}</ClientProduct>
          <Abstract>{abstract}</Abstract>
          <Position>{position}</Position>
        </Case>
      </Content>
      <GenericLink
        showDecorator
        role='link'
        wagtailLink={{
          anchor: '',
          label: 'browse all',
          pagePath: indexPagePath,
          url: null
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: linear-gradient(72.64deg, #2d3262 43.72%, #4f59be 90.98%);
  padding: 110px 160px;
  & > a {
    color: ${({ theme }) => theme.colors.secondary[500]};
    text-decoration: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    gap: 50px;
    padding: 50px 20px;
  }
`
const Content = styled.div`
  > * {
    flex: 1;
  }
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    margin-bottom: 110px;
  }
`
const TextWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.neutrals[100]};
  }
`
const Case = styled(Link)`
  text-decoration: none;
  img {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 20px;
  }
`
const ClientProduct = styled(H4.Normal)`
  color: ${({ theme }) => theme.colors.secondary[500]};
`
const Abstract = styled(Text.Big)`
  color: ${({ theme }) => theme.colors.neutrals[100]};
  margin-bottom: 25px;
`
