import React from 'react'

import { WagtailImage } from '@/components/blocks/Image'

import type { ClientBlock } from '@/models/CarouselBlocks'

export const Client = ({ id, block }: { block: ClientBlock; id?: string }) => {
  if (!block) return null

  const { value } = block

  return (
    <>
      {value.options.map((imageBlock, i) => (
        <WagtailImage key={i} id={id} block={imageBlock} />
      ))}
    </>
  )
}
