import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import Link from 'next/link'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H4, Text } from '@/components/commons/Typography'
import { Position } from '@/components/commons/Position'
import { WagtailImage } from '@/components/blocks/Image'

import type { WagtailCaseStudyPage } from '@/models/Pages'

export const CaseStudyPagePreview = ({
  page
}: {
  page: WagtailCaseStudyPage
}) => {
  const { query } = useRouter()
  const { colors } = useTheme()

  if (isEmpty(page)) return null

  return (
    <Wrapper href={`/${query.parent}/${query.child}/${page.meta.slug}`}>
      <CustomImage block={page?.options?.[0]} />
      <div>
        <H4.Normal color={colors.secondary[200]}>
          {page.productName} | {page.clientName}
        </H4.Normal>
        <Text.Big>{page.abstract}</Text.Big>
      </div>
      <Position>{page.position}</Position>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const CustomImage = styled(WagtailImage)`
  border-radius: 20px;
  width: 100%;
`
