import isUndefined from 'lodash/isUndefined'
import React from 'react'

import { Accordion } from '@/components/blocks/Accordion'
import { Article } from '@/components/blocks/Article'
import { Articles } from '@/components/blocks/Articles'
import { BlockKeys } from '@/utils/constants'
import { CallToAction } from '@/components/blocks/CTA'
import { Carousel } from '@/components/blocks/Carousel'
import { CaseStudy } from '@/components/blocks/CaseStudy'
import { Code } from '@/components/blocks/Code'
import { CoverImage } from '@/components/blocks/CoverImage'
import { Hero } from '@/components/blocks/Hero'
import { ImageLinkText } from '@/components/blocks/ImageLinkText'
import { JobPositions } from '@/components/blocks/JobPositions'
import { LabelValueImageText } from '@/components/blocks/LabelValueImageText'
import { MultilineCardsList } from '@/components/blocks/MultilineCardsList'
import { Offices } from '@/components/blocks/Offices'
import { Product } from '@/components/blocks/Product'
import { Products } from '@/components/blocks/Products'
import { Section } from '@/components/blocks/Section'
import { SimpleText } from '@/components/blocks/SimpleText'
import { SingleColumnCardsList } from '@/components/blocks/SingleColumnCardsList'
import { Socials } from '@/components/blocks/Socials'
import { TagsText } from '@/components/blocks/TagsText'
import { TeamMember } from '@/components/blocks/TeamMember'
import { TeamsMembers } from '@/components/blocks/TeamsMembers'
import { Testimonial } from '@/components/blocks/Testimonial'
import { WagtailImage } from '@/components/blocks/Image'

import type { Block } from '@/models/Blocks'
import { AdvancedImageText } from '@/components/blocks/AdvancedImageText'

const GenerateWagtailBlocks = ({
  blocks,
  isArticle,
  isSection,
  prefix,
  sectionBackground
}: {
  blocks: Block[]
  isArticle?: boolean
  isSection?: boolean
  prefix: string
  sectionBackground?: string | null
}) => {
  const generateBlockByType = (block: Block, id: string): JSX.Element => {
    switch (block.type) {
      case BlockKeys.accordion:
        return <Accordion block={block} id={id} />
      case BlockKeys.advancedimagetext:
        return <AdvancedImageText block={block} id={id} />
      case BlockKeys.article:
        return <Article block={block} id={id} />
      case BlockKeys.articles:
        return <Articles block={block} id={id} />
      case BlockKeys.carousel:
        return (
          <Carousel
            block={block}
            id={id}
            sectionBackground={sectionBackground}
          />
        )
      case BlockKeys.casestudy:
        return <CaseStudy block={block} id={id} />
      case BlockKeys.code:
        return <Code block={block} id={id} />
      case BlockKeys.coverimage:
        return <CoverImage block={block} id={id} />
      case BlockKeys.cta:
        return (
          <CallToAction
            block={block}
            id={id}
            isArticle={isArticle}
            isSection={!isUndefined(sectionBackground || isSection)}
          />
        )
      case BlockKeys.hero:
        return <Hero block={block} id={id} />
      case BlockKeys.image:
        return (
          <WagtailImage
            block={block}
            id={id}
            isSection={!isUndefined(sectionBackground) || isSection}
          />
        )
      case BlockKeys.imagelinktext:
        return <ImageLinkText block={block} id={id} />
      case BlockKeys.jobpositions:
        return <JobPositions block={block} id={id} />
      case BlockKeys.labelvalueimagetext:
        return <LabelValueImageText block={block} id={id} />
      case BlockKeys.multilinecardslist:
        return (
          <MultilineCardsList
            block={block}
            id={id}
            isSection={!isUndefined(sectionBackground) || isSection}
          />
        )
      case BlockKeys.offices:
        return <Offices block={block} id={id} />
      case BlockKeys.product:
        return <Product block={block} id={id} />
      case BlockKeys.products:
        return <Products block={block} id={id} />
      case BlockKeys.section:
        return <Section block={block} id={id} pagePrefix={id} />
      case BlockKeys.singlecolumncardslist:
        return <SingleColumnCardsList block={block} id={id} />
      case BlockKeys.socials:
        return <Socials block={block} id={id} />
      case BlockKeys.tagstext:
        return <TagsText block={block} id={id} />
      case BlockKeys.teammember:
        return <TeamMember block={block} id={id} />
      case BlockKeys.teamsmembers:
        return <TeamsMembers block={block} id={id} pagePrefix={id} />
      case BlockKeys.testimonials:
        return <Testimonial block={block} id={id} />
      case BlockKeys.text:
        return <SimpleText block={block} id={id} isArticle={isArticle} />
      default:
        console.error(block)
        return <p>Block type not supported</p>
    }
  }

  return (
    <>
      {blocks &&
        blocks.map((block, i) => (
          <React.Fragment key={i}>
            {generateBlockByType(block, `${prefix}-${i + 1}`)}
          </React.Fragment>
        ))}
    </>
  )
}

export { GenerateWagtailBlocks }
