import { MdLocationPin } from 'react-icons/md'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Text } from '@/components/commons/Typography'

import type { ReactNode } from 'react'

export const Position = ({
  children,
  color
}: {
  children: ReactNode
  color?: string
}) => {
  const { colors } = useTheme()
  return (
    <Wrapper color={color || colors.secondary[500]}>
      <MdLocationPin size={20} />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(Text.SmallBold)`
  text-transform: uppercase;
  display: flex;
  gap: 8px;
`
