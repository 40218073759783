import { useRouter } from 'next/router'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'

import { Article } from '@/components/blocks/Article'
import { ArticlePagePreview } from '@/components/ArticlePagePreview'
import { CaseStudyPagePreview } from '@/components/CaseStudyPagePreview'
import { Pagination } from '@/components/commons/Pagination'
import { PublicationGrid } from '@/components/PublicationGrid'
import { SupportedPageTypes } from '@/utils/constants'

import type { PagesResponse } from '@/models/Api'
import type {
  WagtailArticlePage,
  WagtailCaseStudyPage,
  WagtailPublicationsPage
} from '@/models/Pages'

export const GeneratePublicationsGrid = ({
  changePage,
  childPages,
  page
}: {
  changePage?: (
    direction: 'next' | 'prev' | null,
    pageToGo?: string,
    refToScroll?: React.MutableRefObject<null | HTMLDivElement>
  ) => void
  childPages?: PagesResponse
  page: WagtailPublicationsPage
}) => {
  const { query } = useRouter()

  const totalNumberOfPages = useMemo(
    () =>
      Math.ceil(
        (page.options.length > 0 ? page?.totalItems - 1 : page?.totalItems) /
          page?.pageSize
      ) || 1,
    [page.options.length, page?.pageSize, page?.totalItems]
  )

  const scrollToRef = useRef(null)
  const updatePage = (direction: 'prev' | 'next' | null, pageToGo?: string) => {
    changePage && changePage(direction, pageToGo, scrollToRef)
  }

  return (
    <Wrapper ref={scrollToRef}>
      {page.options.map((block, i) => (
        <Article block={block} key={i} featured />
      ))}
      <CustomGrid
        columnsNumber={page?.columnsNumber}
        hasPagination={totalNumberOfPages > 1}
      >
        {childPages?.items?.map(page =>
          page.meta.type === SupportedPageTypes['publications.ArticlePage'] ? (
            <ArticlePagePreview
              key={page.id}
              page={page as WagtailArticlePage}
            />
          ) : (
            <CaseStudyPagePreview
              key={page.id}
              page={page as WagtailCaseStudyPage}
            />
          )
        )}
      </CustomGrid>
      {changePage && (
        <Pagination
          changePage={updatePage}
          numberOfPages={totalNumberOfPages}
          currentPage={query.page as string}
        />
      )}
    </Wrapper>
  )
}

const CustomGrid = styled(PublicationGrid)<{ hasPagination: boolean }>`
  margin-bottom: ${({ hasPagination }) => (!hasPagination ? '140px' : '0')};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 110px 160px 50px;
  margin: auto;
  background: ${({ theme }) => theme.colors.neutrals[100]};
  gap: 64px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    gap: 24px;
  }
`
