import React from 'react'
import styled, { useTheme } from 'styled-components'

import { GenericLink } from '@/components/commons/GenericLink'
import { H4, Text } from '@/components/commons/Typography'
import { Tag } from '@/components/commons/Tag'
import { Position } from '@/components/commons/Position'
import { RichTextParser } from '@/components/commons/RichTextParser'
import { WagtailImage } from '@/components/blocks/Image'

import type { ProductBlock } from '@/models/Blocks'

export const Product = ({
  block,
  id
}: {
  block: ProductBlock
  id?: string
}) => {
  const theme = useTheme()

  if (!block) return null

  const { client, position, technologies, text, title, type, link } =
    block.value

  return (
    <Wrapper id={id}>
      <WagtailImage block={client.options[0]} />
      <ContentWrapper>
        <ProductName>{title}</ProductName>
        <ClientName>{client.name}</ClientName>
        <Description htmlContent={text} />
        <Technologies>{technologies}</Technologies>
      </ContentWrapper>
      <TagWrapper>
        <Tag color={theme.colors.neutrals[0]}>{type}</Tag>
        <Position>{position}</Position>
      </TagWrapper>
      {link?.label && (
        <CaseStudyLink showDecorator role={'link'} wagtailLink={link} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ProductName = styled(H4.Normal)`
  color: ${({ theme }) => theme.colors.secondary[100]};
`

const ClientName = styled(Text.NormalBold)`
  color: ${({ theme }) => theme.colors.secondary[100]};
`

const Description = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.neutrals[700]};
`

const Technologies = styled(Text.Normal)`
  color: ${({ theme }) => theme.colors.neutrals[900]};
`

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

const CaseStudyLink = styled(GenericLink)`
  color: ${({ theme }) => theme.colors.secondary[200]};
`
