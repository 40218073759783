import { useWindowSize } from 'usehooks-ts'
import React from 'react'
import styled from 'styled-components'

import { GalleryCard } from '@/components/blocks/GalleryCard'
import { SingleColumnCard } from '@/components/blocks/SingleColumnCard'
import { SingleColumnCardItemBlockKeys } from '@/utils/constants'
import { Slider } from '@/components/commons/Slider'

import type { SingleColumnCardBlockItem } from '@/models/SingleColumnCardsListBlocks'
import type { SingleColumnCardsListBlock } from '@/models/Blocks'

export const SingleColumnCardsList = ({
  id,
  block
}: {
  block: SingleColumnCardsListBlock
  id?: string
}) => {
  const { width } = useWindowSize()

  if (!block || !block.value) return null

  const {
    value: { cards, isSliderOnMobile }
  } = block

  if (!cards) return null

  const generateCardsBlockByType = (
    block: SingleColumnCardBlockItem,
    id: string
  ): JSX.Element => {
    switch (block.type) {
      case SingleColumnCardItemBlockKeys.gallerycard:
        return <GalleryCard block={block} id={id} />
      case SingleColumnCardItemBlockKeys.singlecolumncard:
        return <SingleColumnCard block={block} id={id} />
      default:
        return <p>Block type not supported</p>
    }
  }

  if (width < 769 && isSliderOnMobile) {
    return (
      <Slider
        initialSlide={0}
        areDotsVisible
        sliderOptions={{
          loop: true,
          mode: 'snap',
          slides: { origin: 'center', perView: 1.1, spacing: 30 }
        }}
      >
        {cards.map((card, i) => (
          <React.Fragment key={i}>
            {generateCardsBlockByType(card, `${id}-cards-item-${i + 1}`)}
          </React.Fragment>
        ))}
      </Slider>
    )
  }

  return (
    <Container id={id}>
      {cards.map((card, i) => (
        <React.Fragment key={i}>
          {generateCardsBlockByType(card, `${id}-cards-item-${i + 1}`)}
        </React.Fragment>
      ))}
    </Container>
  )
}

const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 0 160px;
    & > div:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals[500]};
    }
    & > div:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`
