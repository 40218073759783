import React from 'react'

import { WagtailImage } from '@/components/blocks/Image'

import type { TechnologyBlock } from '@/models/CarouselBlocks'

export const Technology = ({
  id,
  block
}: {
  block: TechnologyBlock
  id?: string
}) => {
  if (!block) return null

  const { value } = block

  return (
    <>
      {value.options.map((imageBlock, i) => (
        <WagtailImage key={i} id={id} block={imageBlock} />
      ))}
    </>
  )
}
