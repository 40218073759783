import React from 'react'
import styled, { useTheme } from 'styled-components'

import { RichTextParser } from '@/components/commons/RichTextParser'
import { Text } from '@/components/commons/Typography'

import type { SimpleTextBlock } from '@/models/Blocks'

export const SimpleText = ({
  block,
  id,
  isArticle
}: {
  block: SimpleTextBlock
  id?: string
  isArticle?: boolean
}) => {
  const { colors } = useTheme()
  if (!block || !block.value) return null

  const {
    value: { title, text }
  } = block

  return (
    <Container id={id} isArticle={isArticle}>
      {title && (
        <Text.SmallBold color={colors.secondary[100]} uppercase>
          {title}
        </Text.SmallBold>
      )}
      <StyledRichTextParser htmlContent={text} />
    </Container>
  )
}

const Container = styled.div<{ isArticle?: boolean }>`
  padding: ${({ isArticle }) => (isArticle ? '0' : '110px 0 80px')};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: ${({ isArticle }) => (isArticle ? '0' : '0 auto')};
  max-width: ${({ isArticle }) => (isArticle ? 'none' : '60%')};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: none;
    padding: 50px 20px;
  }
`

const StyledRichTextParser = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.secondary[200]};
`
