import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import React from 'react'
import styled from 'styled-components'

export const Pagination = ({
  changePage,
  currentPage,
  numberOfPages
}: {
  changePage: (direction: 'prev' | 'next' | null, pageToGo?: string) => void
  currentPage: string
  numberOfPages: number
}) => {
  if (!currentPage || numberOfPages === 0 || numberOfPages === 1) return null

  return (
    <Wrapper>
      {Number(currentPage) > 1 && (
        <PageIndicator
          disabled={currentPage === '1'}
          onClick={() => changePage('prev')}
          aria-label={`Change to previous page`}
        >
          <MdChevronLeft role='img' size={32} />
        </PageIndicator>
      )}
      <Counter>
        {Array.from(Array(numberOfPages).keys()).map(index => {
          const pageNumber = index + 1
          return (
            <PageNumber
              disabled={pageNumber.toString() === currentPage}
              key={pageNumber}
              onClick={() => changePage(null, pageNumber.toString())}
              aria-label={`Change page to ${pageNumber}`}
            >
              <p>{pageNumber}</p>
            </PageNumber>
          )
        })}
      </Counter>
      {Number(currentPage) < numberOfPages && (
        <PageIndicator
          disabled={Number(currentPage) + 1 > numberOfPages}
          onClick={() => changePage('next')}
          aria-label={`Change to next page`}
        >
          <MdChevronRight role='img' size={32} />
        </PageIndicator>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Counter = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 10px;
`

const PageNumber = styled.button`
  transition: all 0.3s;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: none;
  border: solid 1px transparent;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutrals[700]};
  p {
    margin: 0;
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.neutrals[700]};
    background: ${({ theme }) => theme.colors.secondary[100]};
    border: solid 1px transparent;
    color: white;
  }
  transition: border 0.3s ease-in-out;
  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.secondary[100]};
  }
`

const PageIndicator = styled.button`
  background: transparent;
  border: none;
  height: 50px;
  min-width: 50px;
  border-radius: 50px;
  border: solid 1px transparent;
  color: ${({ theme }) => theme.colors.secondary[100]};
  transition: border 0.3s ease-in-out;
  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.secondary[100]};
  }
`
