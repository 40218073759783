import React from 'react'
import styled from 'styled-components'

import { AlignmentChoices } from '@/utils/constants'
import { GenericLink } from '@/components/commons/GenericLink'
import { H3 } from '@/components/commons/Typography'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { Alignment } from '@/models/Utils'
import type { CallToActionBlock } from '@/models/Blocks'

export const CallToAction = ({
  block,
  className,
  id,
  isArticle,
  isSection
}: {
  block?: CallToActionBlock
  className?: string
  id?: string
  isArticle?: boolean
  isSection?: boolean
}) => {
  if (!block || !block.value) return null

  const {
    value: { title, text, link, alignment }
  } = block

  return (
    <Wrapper
      alignment={alignment}
      className={className || ''}
      isSection={isSection}
      isArticle={isArticle}
      id={id}
    >
      <TextWrapper>
        {title && <H3.Normal>{title}</H3.Normal>}
        <RichTextParser htmlContent={text} />
      </TextWrapper>
      <GenericLink
        wagtailLink={link}
        role={'button'}
        ui={isSection || isArticle ? 'secondary' : 'primary'}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  alignment: Alignment
  isArticle?: boolean
  isSection?: boolean
}>`
  background: ${({ isSection, theme }) =>
    isSection
      ? 'linear-gradient(67.86deg, #4F59BE 38.06%, #727CE3 79.17%);'
      : theme.colors.neutrals[0]};
  border-radius: ${({ isArticle }) => (isArticle ? '0' : '20px')};
  border-top: 1px solid
    ${({ isArticle, theme }) =>
      isArticle ? theme.colors.neutrals[500] : 'transparent'};
  margin: ${({ isArticle, isSection }) =>
    isSection ? '0 160px' : isArticle ? '80px 0' : 'auto'};
  display: flex;
  flex-direction: ${({ alignment }) =>
    alignment === AlignmentChoices.center ? 'column' : 'row'};
  justify-content: space-between;
  text-align: ${({ alignment }) => alignment};
  align-items: center;
  gap: 18px;
  color: ${({ isSection, theme }) =>
    isSection ? theme.colors.neutrals[0] : theme.colors.neutrals[900]};
  padding: ${({ isArticle, isSection }) =>
    isSection ? '35px' : isArticle ? '40px 0' : '100px 20px 110px'}!important;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ isArticle, isSection, theme }) =>
      isSection
        ? theme.colors.neutrals[0]
        : isArticle
        ? theme.colors.primary[100]
        : theme.colors.neutrals[900]};
  }
  a {
    white-space: nowrap;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    align-items: ${({ alignment }) =>
      alignment === AlignmentChoices.center ? 'center' : 'flex-start'};
    margin: ${({ isSection }) => (isSection ? '0 20px' : 'auto')};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 40%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: 100%;
  }
`
