import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H4, Text } from '@/components/commons/Typography'
import { Position } from '@/components/commons/Position'

import type { OfficesBlock } from '@/models/Blocks'

export const Offices = ({
  block,
  id
}: {
  block: OfficesBlock
  id?: string
}) => {
  const { colors } = useTheme()

  if (!block || !block.value) return null

  return (
    <Wrapper id={id}>
      <div />
      <OfficeList>
        {block.value.map(({ city, address, country }, i) => (
          <Office key={i}>
            <H4.Normal color={colors.secondary[100]}>{city}</H4.Normal>
            <Text.Big color={colors.neutrals[800]}>{address}</Text.Big>
            <Position color={colors.secondary[400]}>{country}</Position>
          </Office>
        ))}
      </OfficeList>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  padding: 0 160px 90px;
  gap: 100px;
  & > * {
    flex: 4;
  }
  & > *:last-child {
    flex: 5;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 0 30px 70px;
    > div:first-of-type {
      display: none;
    }
  }
`

const OfficeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`

const Office = styled.div`
  p:last-child {
    margin-top: 25px;
  }
`
