import React from 'react'
import styled from 'styled-components'

import { AlignmentChoices } from '@/utils/constants'
import { EyebrowTitle } from '@/components/commons/EyebrowTitle'
import { GenericLink } from '@/components/commons/GenericLink'
import { H1, H2 } from '@/components/commons/Typography'
import { RichTextParser } from '@/components/commons/RichTextParser'
import { RenditionImage } from '@/components/commons/RenditionImage'

import type { Alignment } from '@/models/Utils'
import type { HeroBlock } from '@/models/Blocks'

export const Hero = ({ block, id }: { block: HeroBlock; id?: string }) => {
  if (!block || !block.value) return null

  const { alignment, style, headline, link, text, title, image } = block.value

  const { background, headlineColor, titleColor, textColor, linkColor } = style

  return (
    <Background alignment={alignment} background={background} id={id}>
      <Decorator alignment={alignment} image={image} />
      {alignment === AlignmentChoices.center ? (
        <EyebrowTitle headline={headline} color={headlineColor}>
          <H1.Normal color={titleColor}>{title}</H1.Normal>
        </EyebrowTitle>
      ) : (
        <EyebrowTitle headline={headline} color={headlineColor}>
          <H2.Normal color={titleColor}>{title}</H2.Normal>
        </EyebrowTitle>
      )}
      <CustomRichTextParse
        htmlContent={text}
        linkColor={linkColor}
        textColor={textColor}
      />
      <GenericLink wagtailLink={link} role={'button'} />
    </Background>
  )
}

const Background = styled.div<{ alignment: Alignment; background: string }>`
  background: ${({ background }) => background};
  position: relative;
  & > * {
    z-index: 1;
  }
  min-height: 100vh;
  padding: 200px 20px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 27px;
  color: ${({ theme }) => theme.colors.neutrals[0]};
  text-align: ${({ alignment }) => alignment};
  align-items: ${({ alignment }) =>
    alignment === AlignmentChoices.center ? alignment : 'flex-start'};
  h2 {
    max-width: 70%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    min-height: max(750px, 80vh);
    padding: 40px 160px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    h1 {
      font-size: 4rem;
      line-height: 1.25;
    }
    h2 {
      font-size: ${({ theme }) => theme.titles.h3.normal?.fontSize};
      max-width: none;
    }
  }
`

const CustomRichTextParse = styled(RichTextParser)<{
  linkColor: string
  textColor: string
}>`
  p {
    color: ${({ textColor }) => textColor};
    @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
      max-width: 400px;
    }
  }
  a {
    color: ${({ linkColor }) => linkColor};
  }
`

const Decorator = styled(RenditionImage)<{ alignment: Alignment }>`
  position: absolute;
  pointer-events: none;
  user-select: none;
  max-width: 100%;
  right: ${({ alignment }) =>
    alignment === AlignmentChoices.center ? 'auto' : '0'};
  z-index: 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: calc(100% - 40px);
    top: calc(50% - 20px);
    transform: translateY(-50%);
    display: ${({ alignment }) =>
      alignment === AlignmentChoices.center ? 'auto' : 'none'};
  }
`
