import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H2, Text } from '@/components/commons/Typography'
import { RichTextParser } from '@/components/commons/RichTextParser'
import { Tag } from '@/components/commons/Tag'

import type { TagsTextBlock } from '@/models/Blocks'

export const TagsText = ({
  block,
  id
}: {
  block: TagsTextBlock
  id: string
}) => {
  const { colors } = useTheme()
  if (!block || !block.value) return null

  const {
    value: { text, title, tagsLists }
  } = block

  return (
    <Wrapper id={id}>
      <div>
        {title && <H2.Normal color={colors.secondary[100]}>{title}</H2.Normal>}
        <RichTextParser htmlContent={text} />
      </div>
      <TagsListsWrapper>
        {tagsLists.map(list => (
          <TagWrapper background={list.background} key={list.title}>
            <Text.BigBold color={colors.secondary[100]}>
              {list.title}
            </Text.BigBold>
            <div>
              {list.tags.map(tag => (
                <Tag key={tag} color={colors.tertiary[500]}>
                  <TagsContent>{tag}</TagsContent>
                </Tag>
              ))}
            </div>
          </TagWrapper>
        ))}
      </TagsListsWrapper>
    </Wrapper>
  )
}

const TagsListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

const TagWrapper = styled.div<{ background?: string }>`
  background: ${({ background }) => background || 'transparent'};
  padding: 24px;
  border-radius: 20px;
  > div {
    margin-top: 13px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 90px;
  h2 {
    margin-bottom: 16px;
  }
  > * {
    width: 50%;
  }
  padding: 110px 160px;
  img {
    border-radius: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    gap: 30px;
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`

const TagsContent = styled(Text.SmallBold)`
  color: ${({ theme }) => theme.colors.secondary[100]};
  margin: 0;
  padding: 3px 8px;
`
