import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Eyebrow } from '@/components/commons/EyebrowTitle'
import { GenericLink } from '@/components/commons/GenericLink'
import { H4 } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'
import { Slide } from '@/components/commons/Slide'
import { Slider } from '@/components/commons/Slider'

import type { GalleryCardBlock } from '@/models/SingleColumnCardsListBlocks'

export const GalleryCard = ({
  block,
  id
}: {
  block: GalleryCardBlock
  id: string
}) => {
  const { colors } = useTheme()

  if (!block || !block.value) return null

  const { gallery, headline, text, title, link } = block.value

  return (
    <Wrapper id={id}>
      <TextWrapper>
        <Eyebrow>{headline}</Eyebrow>
        <H4.Normal color={colors.secondary[100]}>{title}</H4.Normal>
        <RichTextParser htmlContent={text} />
        <GenericLinkCustom wagtailLink={link} role={'link'} showDecorator />
      </TextWrapper>
      <Slider
        areDotsVisible
        initialSlide={0}
        sliderOptions={{
          loop: true,
          mode: 'snap',
          breakpoints: {
            '(min-width: 0px)': {
              slides: { origin: 'center', perView: 1, spacing: 20 }
            },
            '(min-width: 768px)': {
              slides: {
                perView: 1,
                spacing: 30
              }
            }
          }
        }}
      >
        {gallery.map((image, i) => (
          <CustomSlide key={i}>
            <RenditionImage image={image} />
          </CustomSlide>
        ))}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 48px 0;
  > div {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    > div {
      width: 100%;
    }
    overflow: hidden;
    padding: 48px 24px;
    gap: 30px;
    flex-direction: column-reverse;
    background: ${({ theme }) => theme.colors.neutrals[100]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h4 {
    margin-bottom: 0;
  }
  p {
    color: ${({ theme }) => theme.colors.neutrals[800]};
  }
`

const CustomSlide = styled(Slide)`
  border-radius: 20px;
  img {
    object-fit: cover;
    border-radius: 20px;
  }
`

const GenericLinkCustom = styled(GenericLink)`
  color: ${({ theme }) => theme.colors.secondary[200]};
`
