import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { Article } from '@/components/blocks/Article'
import { convertArticlePageToArticleBlock } from '@/utils/functions'

import type { WagtailArticlePage } from '@/models/Pages'

export const ArticlePagePreview = ({ page }: { page: WagtailArticlePage }) => {
  const { query } = useRouter()

  if (isEmpty(page)) return null

  return (
    <Article
      block={convertArticlePageToArticleBlock(
        page,
        `/${query.parent}/${query.child}/${page.meta.slug}`
      )}
    />
  )
}
