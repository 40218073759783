import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H2 } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { AdvancedImageTextBlock } from '@/models/Blocks'

export const AdvancedImageText = ({
  block,
  id
}: {
  block: AdvancedImageTextBlock
  id?: string
}) => {
  const { colors } = useTheme()

  if (!block) return null

  const {
    value: { background, image, text, title }
  } = block

  return (
    <Background background={background} id={id}>
      <Wrapper>
        <ImageWrapper>
          <RenditionImage image={image} />
        </ImageWrapper>
        <TextWrapper>
          {title && (
            <H2.Normal color={colors.secondary[100]}>{title}</H2.Normal>
          )}
          <StyledRichTextParser htmlContent={text} />
        </TextWrapper>
      </Wrapper>
    </Background>
  )
}

const Background = styled.div<{ background: string | null }>`
  background: ${({ background, theme }) =>
    background || theme.colors.neutrals[0]};
  padding-bottom: 110px;
`

const Wrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 140px 160px 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 48px 24px;
    gap: 30px;
    border-radius: 20px;
    flex-direction: column-reverse;
    background: ${({ theme }) => theme.colors.neutrals[100]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledRichTextParser = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.neutrals[900]};
`
