import React from 'react'
import styled from 'styled-components'

import type { ReactNode } from 'react'

type Props = {
  children: ReactNode[] | ReactNode
  color?: string
  headline: string | null
}

const EyebrowTitle = ({ children, color, headline }: Props) => {
  return (
    <Container>
      {headline && <Eyebrow color={color}>{headline}</Eyebrow>}
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Eyebrow = styled.h4<{ color?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.29;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${({ color, theme }) => color || theme.colors.secondary[500]};
  margin-bottom: 10px;
`

export { EyebrowTitle }
