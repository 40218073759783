import React from 'react'
import styled, { useTheme } from 'styled-components'

import { Text } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { MultilineCardBlock } from '@/models/MultilineCardsListBlocks'

export const MultilineCard = ({
  block,
  id
}: {
  block: MultilineCardBlock
  id?: string
}) => {
  const { colors } = useTheme()

  if (!block) return null

  const {
    value: { image, text, title, background }
  } = block

  return (
    <Wrapper id={id} className='keen-slider__slide' background={background}>
      <RenditionImage image={image} />
      <TextWrapper>
        {title && (
          <Text.BigBold color={colors.secondary[100]}>{title}</Text.BigBold>
        )}
        <StyledRichTextParser htmlContent={text} />
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: ${({ background }) => background};
  img {
    object-fit: cover;
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 48px 24px;
    gap: 30px;
    border-radius: 20px;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.neutrals[100]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 24px;
`

const StyledRichTextParser = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.neutrals[900]};
`
