import React from 'react'
import styled from 'styled-components'

import { parseHTML } from '@/utils/functions'

type Props = {
  className?: string
  htmlContent: string
}

const RichTextParser = ({ className, htmlContent }: Props) => {
  if (!htmlContent) return null
  return (
    <RichTextStyle
      className={className || ''}
      dangerouslySetInnerHTML={{ __html: parseHTML(htmlContent) }}
    />
  )
}

const RichTextStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    font-weight: ${({ theme }) => theme.texts.normal.fontWeight};
    font-size: ${({ theme }) => theme.texts.normal.fontSize};
    line-height: ${({ theme }) => theme.texts.normal.lineHeight};
    margin: unset;
  }
  h1 {
    font-weight: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.fontWeight};
    font-size: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.fontSize};
    line-height: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.lineHeight};
    margin: unset;
  }
  h2 {
    font-weight: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.fontWeight};
    font-size: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.fontSize};
    line-height: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.lineHeight};
    margin: unset;
  }
  h3 {
    font-weight: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.fontWeight};
    font-size: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.fontSize};
    line-height: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.lineHeight};
    margin: unset;
  }
  h4 {
    font-weight: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.fontWeight};
    font-size: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.fontSize};
    line-height: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.lineHeight};
    margin: unset;
  }
  h5 {
    font-weight: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.fontWeight};
    font-size: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.fontSize};
    line-height: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.lineHeight};
    margin: unset;
  }
`

export { RichTextParser }
