import { useWindowSize } from 'usehooks-ts'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { GenericLink } from '@/components/commons/GenericLink'
import { H4, Text } from '@/components/commons/Typography'
import { JobApplication } from '@/components/JobApplication'
import { Position } from '@/components/commons/Position'

import type { JobPositionsBlock } from '@/models/Blocks'

export const JobPositions = ({
  id,
  block
}: {
  block: JobPositionsBlock
  id?: string
}) => {
  const { width } = useWindowSize()

  const { breakpoint, colors } = useTheme()

  if (!block) return null

  const { value } = block

  return (
    <JobsWrapper id={id}>
      {value.map((job, i) => (
        <JobCard key={i}>
          <TextWrapper>
            {width < breakpoint.lg && (
              <JobApplication
                applicationType={job.applicationType}
                showDecorator={false}
              />
            )}
            <H4.Normal color={colors.secondary[100]}>{job.title}</H4.Normal>
            {job.description && <Text.Normal>{job.description}</Text.Normal>}
            <Row>
              {width >= breakpoint.lg && (
                <JobApplication applicationType={job.applicationType} />
              )}
              <Position color={colors.secondary[100]}>{job.location}</Position>
            </Row>
          </TextWrapper>
          <GenericLink
            wagtailLink={job.link}
            role={'button'}
            ui={'secondary'}
          />
        </JobCard>
      ))}
    </JobsWrapper>
  )
}

const JobsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 160px 110px;
  margin-top: -48px;
  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary[300]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-top: -25px;
    padding: 0 24px 48px;
  }
`

const JobCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 180px;
  padding: 30px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 40%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: 100%;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
  }
`
