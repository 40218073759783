import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useKeenSlider } from 'keen-slider/react'
import React, { Children, useState } from 'react'
import styled from 'styled-components'

import type { KeenSliderOptions } from 'keen-slider/react'
import type { ReactNode } from 'react'

export const Slider = ({
  children,
  initialSlide = 1,
  sliderOptions,
  areArrowsVisible = false,
  areDotsVisible = false
}: {
  children: ReactNode[]
  initialSlide?: number
  sliderOptions?: KeenSliderOptions
  areArrowsVisible?: boolean
  areDotsVisible?: boolean
}) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: initialSlide,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
      ...sliderOptions
    },
    []
  )

  const isLoaded =
    loaded && instanceRef.current && Children.toArray(children).length > 1

  return (
    <Wrapper>
      <Content>
        {areArrowsVisible && isLoaded && (
          <Arrow
            className='left'
            role='button'
            onClick={() => instanceRef.current?.prev()}
            aria-label={`Move to previous element`}
          >
            <MdChevronLeft color='white' size={22} />
          </Arrow>
        )}
        <SlideContainer ref={sliderRef} className='keen-slider'>
          {children}
        </SlideContainer>
        {areArrowsVisible && isLoaded && (
          <Arrow
            className='right'
            role='button'
            onClick={() => instanceRef.current?.next()}
            aria-label={`Move to next element`}
          >
            <MdChevronRight color='white' size={22} />
          </Arrow>
        )}
      </Content>
      {areDotsVisible && isLoaded && (
        <DotsWrapper>
          {Array.from(Array(children.length).keys()).map(idx => {
            return (
              <button
                role='button'
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={'dot' + (currentSlide === idx ? ' active' : '')}
                aria-label={`Move to dot ${idx}`}
              ></button>
            )
          })}
        </DotsWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 20px 0;
  max-width: 100%;
`

const Content = styled.div`
  position: relative;
  margin: 0 auto;
`

const SlideContainer = styled.div`
  padding: 25px 0;
  .keen-slider__slide {
    overflow: initial !important;
  }
`

const DotsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  button {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    border: none;
    border: 2px solid ${({ theme }) => theme.colors.secondary[200]};
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  button.active {
    background: ${({ theme }) => theme.colors.secondary[100]};
    border: 2px solid ${({ theme }) => theme.colors.secondary[100]};
  }
`

const buttonArrowWidth = '30px'

const Arrow = styled.button`
  position: absolute;
  top: calc(258px / 2);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${buttonArrowWidth};
  min-height: ${buttonArrowWidth};
  border-radius: ${buttonArrowWidth};
  border: none;
  background: ${({ theme }) => theme.colors.secondary[100]};
  padding: 0;
  cursor: pointer;
  &.left {
    left: ${`calc(-${buttonArrowWidth} - 30px)`};
  }
  &.right {
    right: ${`calc(-${buttonArrowWidth} - 30px)`};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    top: unset;
    bottom: 15px;
  }
`
