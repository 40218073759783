import React from 'react'
import styled from 'styled-components'

export const PublicationGrid = ({
  children,
  className,
  columnsNumber,
  id
}: {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  columnsNumber: number
  id?: string
}) => (
  <Grid columnsNumber={columnsNumber} id={id} className={className}>
    {children}
  </Grid>
)

const Grid = styled.div<{ columnsNumber: number }>`
  display: grid;
  grid-template-columns: ${({ columnsNumber }) =>
    `repeat(${columnsNumber}, minmax(0, 1fr))`};
  gap: 36px;
  grid-row-gap: 64px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    grid-row-gap: 24px;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`
