import React from 'react'
import styled, { css } from 'styled-components'

import { RenditionImage } from '@/components/commons/RenditionImage'

import type { WagtailImageBlock } from '@/models/Blocks'

export const WagtailImage = ({
  block,
  className,
  id,
  isSection
}: {
  block: WagtailImageBlock
  className?: string
  id?: string
  isSection?: boolean
}) => {
  if (!block) return null

  const { value } = block

  return (
    <StyledImage
      id={id}
      image={value}
      isSection={isSection}
      className={className}
    />
  )
}

const StyledImage = styled(RenditionImage)<{ isSection?: boolean }>`
  ${({ isSection, theme }) =>
    isSection &&
    css`
      padding: 0 160px 110px;
      @media (max-width: ${theme.breakpoint.lg}px) {
        padding: 50px 20px;
      }
    `}
`
