import Image from 'next/image'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'

import { BlockKeys } from '@/utils/constants'
import { CallToAction } from '@/components/blocks/CTA'
import { GenericLink } from '@/components/commons/GenericLink'
import { InternalLink } from '@/components/commons/InternalLink'
import { Socials } from '@/components/blocks/Socials'
import { Text } from '@/components/commons/Typography'

import type { FooterBody } from '@/models/Pages'
import type { MergedMenuItemsAndPages } from '@/models/Menus'
import type {
  CallToActionBlock,
  PoliciesBlock,
  SocialsBlock
} from '@/models/Blocks'

const Footer = ({
  body,
  cta,
  menu
}: {
  body: FooterBody
  cta?: CallToActionBlock
  menu?: MergedMenuItemsAndPages[]
}) => {
  const { colors } = useTheme()

  const socials = useMemo(
    () =>
      body && (body.find(e => e.type === BlockKeys.socials) as SocialsBlock),
    [body]
  )

  const policyLinks = useMemo(
    () =>
      body && (body.find(e => e.type === BlockKeys.policies) as PoliciesBlock),
    [body]
  )

  return (
    <Wrapper hideCTA={!cta}>
      <StyledCallToAction block={cta} />
      <FooterMenu>
        {menu?.map((parent, i) => (
          <Column key={i} columnPosition={i + 1}>
            <Text.SmallBold color={colors.neutrals[0]} uppercase>
              {parent.label}
            </Text.SmallBold>
            {parent.child.map((inner, k) => (
              <Text.Small color={colors.neutrals[0]} key={k}>
                <StyledInternalLink
                  role={'link'}
                  wagtailLink={{
                    anchor: '',
                    label: inner.label,
                    pagePath: inner.pagePath,
                    url: ''
                  }}
                />
              </Text.Small>
            ))}
          </Column>
        ))}
      </FooterMenu>
      <SubFooter>
        <Info>
          <Image
            src={`/images/logo-dark.svg`}
            width={93}
            height={40}
            alt={'sito logo'}
          />
          <PoliciesWrapper>
            <Text.Little color={colors.neutrals[0]}>
              © {new Date().getFullYear()} 20tab srl | VAT Nr. 11955811002
            </Text.Little>
            {policyLinks?.value?.map((link, i) => (
              <Policy key={`policy_${i}`}>
                | <GenericLink role={'link'} wagtailLink={link} />
              </Policy>
            ))}
          </PoliciesWrapper>
        </Info>
        <Socials block={socials} />
      </SubFooter>
    </Wrapper>
  )
}

const Wrapper = styled.footer<{ hideCTA: boolean }>`
  background: linear-gradient(69.15deg, #2d4962 0.5%, #37e572 102.43%);
  padding: ${({ hideCTA }) => (!hideCTA ? '195px' : '80px')} 60px 35px;
  position: relative;
  margin-top: ${({ hideCTA }) => (!hideCTA ? '195px' : '0px')};
  & > div:first-child {
    ${({ hideCTA }) =>
      !hideCTA &&
      `
      position: relative;
      top: -195px;
      margin-bottom: -240px;
      transform: translateY(-50%);
  `}
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: ${({ hideCTA }) => (!hideCTA ? '195px' : '35px')} 20px 35px;
    ${({ hideCTA }) =>
      !hideCTA &&
      `
      margin-top: 300px;
      & > div:first-child {
        margin-bottom: -330px;
      }
    `}
  }
`

const FooterMenu = styled.div`
  color: ${({ theme }) => theme.colors.neutrals[0]};
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 2fr 1fr 3fr;
  padding-bottom: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 0 25px 35px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`

const SubFooter = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 45px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    gap: 50px;
    flex-direction: column-reverse;
    padding: 30px 25px 35px;
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    gap: 16px;
  }
`

const Column = styled.div<{ columnPosition: number }>`
  grid-column-start: ${({ columnPosition }) => columnPosition * 2};
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > p:first-child {
    margin-bottom: 8px;
  }
`

const StyledInternalLink = styled(InternalLink)`
  color: ${({ theme }) => theme.colors.neutrals[0]};
`

const StyledCallToAction = styled(CallToAction)`
  box-shadow: 0px 2px 8px rgba(46, 56, 77, 0.08),
    0px 20px 32px rgba(46, 56, 77, 0.24);
  background: url('/images/cta-background.png') top left no-repeat,
    ${({ theme }) => theme.colors.neutrals[0]} left top repeat;
  padding: 50px 120px 33px 150px !important;
  h3 {
    color: ${({ theme }) => theme.colors.primary[100]};
  }
  p {
    color: ${({ theme }) => theme.colors.secondary[100]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 35px 40px 35px !important;
    background: ${({ theme }) => theme.colors.neutrals[0]};
  }
`

const PoliciesWrapper = styled.div`
  display: flex;
  gap: 4px;
  white-space: nowrap;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Policy = styled.div`
  color: ${({ theme }) => theme.colors.neutrals[0]};
  display: flex;
  gap: 4px;
  font-size: ${({ theme }) => theme.texts.little.fontSize};
  a {
    color: ${({ theme }) => theme.colors.neutrals[0]};
    font-size: ${({ theme }) => theme.texts.little.fontSize};
    font-weight: ${({ theme }) => theme.texts.little.fontWeight};
  }
`

export { Footer }
