import React, { useMemo } from 'react'
import styled from 'styled-components'

import { BlockKeys, SupportedPublicationIndexPageType } from '@/utils/constants'
import { Footer } from '@/components/commons/Footer'
import { GeneratePublicationsGrid } from '@/components/GeneratePublicationsGrid'
import { GenerateWagtailBlocks } from '@/components/GenerateWagtailBlocks'
import { Meta } from '@/components/Meta'

import type { CallToActionBlock } from '@/models/Blocks'
import type { MergedMenuItemsAndPages } from '@/models/Menus'
import type { PageDetailResponse, PagesResponse } from '@/models/Api'
import type { WagtailPublicationsPage } from '@/models/Pages'

const GenerateWagtailPage = ({
  baseUrl,
  changePage,
  childPages,
  footerMenu,
  page
}: {
  baseUrl: string
  changePage?: (
    direction: 'prev' | 'next' | null,
    pageToGo?: string
  ) => Promise<void>
  childPages?: PagesResponse
  footerMenu?: MergedMenuItemsAndPages[]
  page: PageDetailResponse
}) => {
  const footerCTA = useMemo(
    () =>
      page?.footer?.body &&
      (page.footer.body.find(
        e => e.type === BlockKeys.cta
      ) as CallToActionBlock),
    [page?.footer]
  )

  return (
    <Container hideCTA={!footerCTA}>
      <Meta meta={page.meta} baseUrl={baseUrl} />
      <GenerateWagtailBlocks blocks={page.body} prefix={page.meta.slug} />
      {page.meta.type in SupportedPublicationIndexPageType && (
        <GeneratePublicationsGrid
          changePage={changePage}
          page={page as WagtailPublicationsPage}
          childPages={childPages}
        />
      )}
      {page.footer && (
        <Footer body={page.footer.body} cta={footerCTA} menu={footerMenu} />
      )}
    </Container>
  )
}

export { GenerateWagtailPage }

const Container = styled.div<{ hideCTA: boolean }>`
  & > *:nth-last-child(2) {
    ${({ hideCTA }) =>
      !hideCTA &&
      `
      margin-bottom: -195px;
      padding-bottom: 175px;
    `}
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    & > *:nth-last-child(2) {
      ${({ hideCTA }) =>
        !hideCTA &&
        `
      margin-bottom: -320px;
      padding-bottom: 300px;
    `}
    }
  }
`
