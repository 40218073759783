import React from 'react'
import styled from 'styled-components'

export const Slide = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <Wrapper className={`keen-slider__slide ${className}`}>{children}</Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 258px;
  min-width: 258px;
`
