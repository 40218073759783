import { useWindowSize } from 'usehooks-ts'
import React from 'react'
import styled from 'styled-components'

import { Article } from '@/components/blocks/Article'
import { BlockKeys } from '@/utils/constants'
import { GenericLink } from '@/components/commons/GenericLink'
import { GenericList } from '@/components/GenericList'
import { PublicationGrid } from '@/components/PublicationGrid'

import type { ArticleBlock, ArticlesBlock, Block } from '@/models/Blocks'

export const Articles = ({
  block,
  id
}: {
  block: ArticlesBlock
  id?: string
}) => {
  const { width } = useWindowSize()

  if (!block) return null

  const {
    value: { columnsNumber, featured, link, publications, isSliderOnMobile }
  } = block

  const mappedPublications: ArticleBlock[] = publications.map(
    (publication, i) => ({
      type: BlockKeys.article,
      value: publication,
      id: `article-${i + 1}-${id}`
    })
  )

  return (
    <Wrapper id={`articles-${id}`}>
      <Article
        block={{
          type: BlockKeys.article,
          value: featured,
          id: `featured-article-${id}`
        }}
        featured
      />
      <GenericList
        list={mappedPublications as Block[]}
        isSlider={width < 769 && isSliderOnMobile}
        pagePrefix={`article-${id}`}
        areDotsVisible
        sliderOptions={{
          loop: true,
          mode: 'snap',
          breakpoints: {
            '(min-width: 0px)': {
              slides: { origin: 'center', perView: 1, spacing: 30 }
            },
            '(min-width: 768px)': {
              slides: {
                perView: 3,
                spacing: 40
              }
            }
          }
        }}
      >
        <PublicationGrid columnsNumber={columnsNumber} id={id}>
          {mappedPublications.map((block, i) => (
            <Article key={i} block={block} />
          ))}
        </PublicationGrid>
      </GenericList>
      <StyledCTA wagtailLink={link} role={'button'} ui={'secondary'} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 90px;
  padding: 0 160px 110px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    gap: 30px;
  }
`

const StyledCTA = styled(GenericLink)`
  align-self: center;
`
