import React from 'react'
import styled, { useTheme } from 'styled-components'

import { RichTextParser } from '@/components/commons/RichTextParser'
import { Slider } from '@/components/commons/Slider'
import { Text } from '@/components/commons/Typography'
import { WagtailImage } from '@/components/blocks/Image'

import type { TestimonialsBlock } from '@/models/Blocks'

export const Testimonial = ({
  block,
  id
}: {
  block: TestimonialsBlock
  id?: string
}) => {
  const theme = useTheme()

  if (!block) return null

  return (
    <Wrapper id={id}>
      <Slider
        areDotsVisible
        sliderOptions={{
          mode: 'snap',
          breakpoints: {
            '(min-width: 0px)': {
              slides: { origin: 'center', perView: 1.1, spacing: 30 }
            },
            '(min-width: 900px)': {
              slides: {
                origin: 'center',
                perView: 1.5,
                spacing: 40
              }
            },
            '(min-width: 1300px)': {
              slides: {
                origin: 'center',
                perView: 2.2,
                spacing: 40
              }
            }
          }
        }}
      >
        {block.value.map((testimonial, i) => (
          <Slide
            className='keen-slider__slide'
            key={i}
            hasImage={
              testimonial?.options.length > 0 &&
              Boolean(testimonial?.options?.[0]?.value?.url)
            }
          >
            <CustomImage block={testimonial?.options?.[0]} />
            <CustomRichTextParser htmlContent={testimonial.text} />
            <TextWrapper>
              <Text.SmallBold color={theme.colors.secondary[200]}>
                {testimonial.clientName}
              </Text.SmallBold>
              <Text.BigBold color={theme.colors.secondary[100]}>
                {testimonial.name}
              </Text.BigBold>
              <Text.Small color={theme.colors.secondary[100]}>
                {testimonial.role}
              </Text.Small>
            </TextWrapper>
          </Slide>
        ))}
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 10px;
  margin: 20px 0;
`

const Slide = styled.div<{ hasImage: boolean }>`
  display: grid;
  box-shadow: -2px 0px 10px 10px rgba(135, 143, 173, 0.04),
    -2px 14px 20px rgba(135, 143, 173, 0.16);
  gap: 40px;
  background: ${({ theme }) => theme.colors.neutrals[0]};
  padding: 50px 40px;
  border-radius: 20px;
  cursor: grab;
  grid-template-columns: 200px auto;
  grid-template-rows: auto;
  grid-template-areas: ${({ hasImage }) =>
    hasImage
      ? `'img longText longText'
    'img info info'`
      : `'longText'
    'info'`};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 35px;
    display: grid;
    gap: 5px;
    grid-template-columns: 100px minmax(0, 1fr);
    grid-template-areas:
      'longText longText'
      'img info';
    img {
      max-height: 100px;
      max-width: 100px;
      object-fit: contain;
    }
  }
`
const CustomImage = styled(WagtailImage)`
  grid-area: img;
  width: 100%;
  height: 100%;
  max-height: 180px;
  object-position: center;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    align-self: flex-end;
  }
`

const CustomRichTextParser = styled(RichTextParser)`
  grid-area: longText;
  p {
    font-size: ${({ theme }) => theme.texts.big?.fontSize};
    color: ${({ theme }) => theme.colors.neutrals[900]};
  }
`

const TextWrapper = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  align-self: flex-end;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p:first-of-type {
    color: ${({ theme }) => theme.colors.neutrals[900]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    justify-content: flex-start;
    align-self: flex-end;
    min-width: unset;
  }
`
