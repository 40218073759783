import { useRouter } from 'next/router'
import Link from 'next/link'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import { ArticleCategory } from '@/components/ArticleCategory'
import { H4, Text } from '@/components/commons/Typography'
import { localeCompactDate } from '@/utils/functions'
import { WagtailImage } from '@/components/blocks/Image'

import type { ArticleBlock } from '@/models/Blocks'

export const Article = ({
  block,
  featured = false,
  id
}: {
  block: ArticleBlock
  featured?: boolean
  id?: string
}) => {
  const { colors } = useTheme()
  const { locale } = useRouter()

  if (!block) return null

  const {
    value: {
      abstract,
      author,
      categories,
      firstPublishedAt,
      options,
      pagePath,
      title
    }
  } = block

  return (
    <Card href={pagePath} id={id} featured={featured}>
      <ImageWrapper featured={featured}>
        <div className='categories'>
          {categories.map(category => (
            <WagtailImage key={category.slug} block={category?.options?.[0]} />
          ))}
        </div>
        <WagtailImage block={options[0]} />
      </ImageWrapper>
      <TextWrapper>
        <div>
          <Categories>
            {categories.map(category => (
              <ArticleCategory key={category.slug} category={category} />
            ))}
          </Categories>
          {featured ? (
            <FeaturedTitle color={colors.secondary[100]}>{title}</FeaturedTitle>
          ) : (
            <Title color={colors.secondary[100]}>{title}</Title>
          )}
          {featured && abstract && (
            <Abstract color={colors.neutrals[900]}>{abstract}</Abstract>
          )}
        </div>
        <Footer>
          <Text.LittleBold color={colors.neutrals[900]}>
            {author}
          </Text.LittleBold>
          <Text.LittleBold color={colors.neutrals[900]}>
            {localeCompactDate(firstPublishedAt, locale as string)}
          </Text.LittleBold>
        </Footer>
      </TextWrapper>
    </Card>
  )
}

const Card = styled(Link)<{ featured: boolean }>`
  text-decoration: none;
  display: flex;
  flex-direction: ${({ featured }) => (featured ? 'row' : 'column')};
  border-radius: 20px;
  min-height: 420px;
  background: ${({ featured, theme }) =>
    featured ? 'transparent' : theme.colors.neutrals[0]};
  box-shadow: ${({ featured }) =>
    featured
      ? '0'
      : '0px 10px 10px rgba(135, 143, 173, 0.04),    0px 14px 20px rgba(135, 143, 173, 0.16);'};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.neutrals[0]};
    box-shadow: 0px 10px 10px rgba(135, 143, 173, 0.04),
      0px 14px 20px rgba(135, 143, 173, 0.16);
  }
`

const Abstract = styled(Text.Normal)`
  @media (max-width: 940px) {
    display: none;
  }
`

const TextWrapper = styled.div`
  padding: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex: 1;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-self: flex-end;
`

const Categories = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

const Title = styled(Text.Big)`
  padding: 16px 0 24px 0;
`

const FeaturedTitle = styled(H4.Normal)`
  padding: 16px 0 24px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: ${({ theme }) => theme.texts.big?.fontSize};
    font-weight: 400;
  }
`

const ImageWrapper = styled.div<{ featured: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  .categories {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    gap: 15px;
    filter: brightness(0) invert(1);
  }
  & > img {
    border-radius: ${({ featured }) => (featured ? '20px' : '20px 20px 0px 0')};
    height: ${({ featured }) => (featured ? 'auto' : '184px')};
    object-fit: cover;
    width: 100%;
  }
  @media (max-width: 940px) {
    & > img {
      border-radius: 0;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      height: 184px;
      width: 100%;
      object-fit: cover;
    }
  }
`
