import React from 'react'
import styled from 'styled-components'

import { BlockKeys } from '@/utils/constants'
import { EyebrowTitle } from '@/components/commons/EyebrowTitle'
import { GenerateWagtailBlocks } from '@/components/GenerateWagtailBlocks'
import { H2 } from '@/components/commons/Typography'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { SectionBlock } from '@/models/Blocks'

export const Section = ({
  block,
  id,
  pagePrefix
}: {
  block: SectionBlock
  id?: string
  pagePrefix: string
}) => {
  if (!block || !block.value) return null

  const {
    value: { style, body, headline, text, title }
  } = block

  const { background, headlineColor, titleColor, textColor, linkColor } = style

  return (
    <Background background={background} id={id} hasChild={body?.length > 0}>
      {(headline || text || title) && (
        <TextWrapper>
          {headline ? (
            <EyebrowTitle color={headlineColor} headline={headline}>
              <H2.Normal color={titleColor}>{title}</H2.Normal>
            </EyebrowTitle>
          ) : (
            title && <H2.Normal color={titleColor}>{title}</H2.Normal>
          )}
          {text && (
            <CustomRichTextParse
              htmlContent={text}
              linkColor={linkColor}
              textColor={textColor}
            />
          )}
        </TextWrapper>
      )}
      <GenerateWagtailBlocks
        blocks={body}
        isSection
        prefix={`${pagePrefix}-${BlockKeys.section}`}
        sectionBackground={background}
      />
    </Background>
  )
}

const Background = styled.div<{ background: string | null; hasChild: boolean }>`
  background: ${({ background }) => background};
  ${({ hasChild }) => hasChild && `padding-bottom: 110px;`};
  display: flex;
  flex-direction: column;
  img {
    align-self: center;
  }
`

const CustomRichTextParse = styled(RichTextParser)<{
  linkColor: string
  textColor: string
}>`
  p {
    color: ${({ textColor }) => textColor};
  }
  a {
    color: ${({ linkColor }) => linkColor};
  }
`

const TextWrapper = styled.div`
  padding: 100px 160px 110px;
  display: flex;
  gap: 100px;
  & > * {
    flex: 4;
  }
  & > *:last-child {
    flex: 5;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    flex-direction: column;
    gap: 30px;
    h2 {
      font-weight: 400;
      font-size: ${({ theme }) => theme.titles.h3.normal?.fontSize};
    }
  }
`
