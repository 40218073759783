import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H2, Text } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { LabelValueImageTextBlock } from '@/models/Blocks'

export const LabelValueImageText = ({
  block,
  id
}: {
  block: LabelValueImageTextBlock
  id?: string
}) => {
  const { colors } = useTheme()
  if (!block || !block.value) return null

  const {
    value: { text, title, image, items }
  } = block

  return (
    <Wrapper id={id}>
      <div>
        {title && (
          <CustomTitle color={colors.secondary[200]}>{title}</CustomTitle>
        )}
        <RichTextParser htmlContent={text} />
        <ExtraDetails>
          {items?.map(({ label, value }) => (
            <div key={label}>
              <Text.Normal color={colors.secondary[400]}>{label}</Text.Normal>
              <Text.BigBold color={colors.neutrals[900]}>{value}</Text.BigBold>
            </div>
          ))}
        </ExtraDetails>
      </div>
      <RenditionImage image={image} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 90px;
  background: ${({ theme }) => theme.colors.neutrals[100]};
  h2 {
    margin-bottom: 16px;
  }
  padding: 110px 160px;
  img {
    border-radius: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 50px 20px;
    gap: 30px;
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`

const CustomTitle = styled(H2.Normal)`
  word-break: break-word;
`

const ExtraDetails = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  p {
    margin: 0;
  }
`
