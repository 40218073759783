import React from 'react'

import { GenerateWagtailBlocks } from '@/components/GenerateWagtailBlocks'
import { Slider } from '@/components/commons/Slider'
import { Slide } from '@/components/commons/Slide'

import type { Block } from '@/models/Blocks'
import type { KeenSliderOptions } from 'keen-slider/react'

export const GenericList = ({
  children,
  isSlider,
  list,
  pagePrefix,
  sliderOptions,
  areArrowsVisible,
  areDotsVisible
}: {
  children: JSX.Element | JSX.Element[] | null
  isSlider: boolean
  list: Block[]
  pagePrefix: string
  sliderOptions?: KeenSliderOptions
  areArrowsVisible?: boolean
  areDotsVisible?: boolean
}) => {
  return (
    <>
      {isSlider ? (
        <Slider
          areArrowsVisible={areArrowsVisible}
          areDotsVisible={areDotsVisible}
          sliderOptions={sliderOptions}
        >
          {list?.map((item, i) => (
            <Slide key={i}>
              <GenerateWagtailBlocks
                blocks={[item]}
                prefix={`${pagePrefix}-slider-${i}`}
              />
            </Slide>
          ))}
        </Slider>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
