import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { WagtailImage } from '@/components/blocks/Image'

import type { SocialsBlock } from '@/models/Blocks'

export const Socials = ({
  id,
  block
}: {
  block: SocialsBlock
  id?: string
}) => {
  if (!block || !block.value) return null

  const { value } = block

  return (
    <>
      {block && (
        <SocialsWrapper id={id}>
          {value.map((social, i) => (
            <Link
              key={i}
              href={social.link}
              target='_blank'
              rel='noreferrer noopener'
            >
              <WagtailImage block={social?.options?.[0]} />
            </Link>
          ))}
        </SocialsWrapper>
      )}
    </>
  )
}

const SocialsWrapper = styled.div`
  display: flex;
  gap: 20px;
`
