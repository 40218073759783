import styled from 'styled-components'

type BaseTextProps = {
  color?: string
  italic?: boolean
  margin?: string
  uppercase?: boolean
}

const GenericTitle = styled.h1<BaseTextProps>`
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin || 0};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  ${({ italic }) => italic && 'font-style: italic;'};
`

export const H1 = {
  Normal: styled(GenericTitle)`
    font-size: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h1.normal && theme.titles.h1.normal.lineHeight};
  `,
  Display: styled(GenericTitle)`
    font-size: ${({ theme }) =>
      theme.titles.h1.display && theme.titles.h1.display.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h1.display && theme.titles.h1.display.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h1.display && theme.titles.h1.display.lineHeight};
  `
}

export const H2 = {
  Normal: styled(GenericTitle).attrs({ as: 'h2' })`
    font-size: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h2.normal && theme.titles.h2.normal.lineHeight};
  `,
  Display: styled(GenericTitle)`
    font-size: ${({ theme }) =>
      theme.titles.h2.display && theme.titles.h2.display.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h2.display && theme.titles.h2.display.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h2.display && theme.titles.h2.display.lineHeight};
  `
}

export const H3 = {
  Normal: styled(GenericTitle).attrs({ as: 'h3' })`
    font-size: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h3.normal && theme.titles.h3.normal.lineHeight};
  `
}

export const H4 = {
  Normal: styled(GenericTitle).attrs({ as: 'h4' })`
    font-size: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h4.normal && theme.titles.h4.normal.lineHeight};
  `
}

export const H5 = {
  Normal: styled(GenericTitle).attrs({ as: 'h5' })`
    font-size: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.fontSize};
    font-weight: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.fontWeight};
    line-height: ${({ theme }) =>
      theme.titles.h5.normal && theme.titles.h5.normal.lineHeight};
  `
}

const GenericText = styled.p<BaseTextProps>`
  color: ${({ color, theme }) => color || theme.colors.neutrals[800]};
  margin: ${({ margin }) => margin || 0};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  ${({ italic }) => italic && 'font-style: italic;'};
`

export const Text = {
  Normal: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.normal.fontSize};
    font-weight: ${({ theme }) => theme.texts.normal.fontWeight};
    line-height: ${({ theme }) => theme.texts.normal.lineHeight};
  `,
  NormalBold: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.normalBold.fontSize};
    font-weight: ${({ theme }) => theme.texts.normalBold.fontWeight};
    line-height: ${({ theme }) => theme.texts.normalBold.lineHeight};
  `,
  Big: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.big.fontSize};
    font-weight: ${({ theme }) => theme.texts.big.fontWeight};
    line-height: ${({ theme }) => theme.texts.big.lineHeight};
  `,
  BigBold: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.bigBold.fontSize};
    font-weight: ${({ theme }) => theme.texts.bigBold.fontWeight};
    line-height: ${({ theme }) => theme.texts.bigBold.lineHeight};
  `,
  Small: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.small.fontSize};
    font-weight: ${({ theme }) => theme.texts.small.fontWeight};
    line-height: ${({ theme }) => theme.texts.small.lineHeight};
  `,
  SmallBold: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.smallBold.fontSize};
    font-weight: ${({ theme }) => theme.texts.smallBold.fontWeight};
    line-height: ${({ theme }) => theme.texts.smallBold.lineHeight};
  `,
  Little: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.little.fontSize};
    font-weight: ${({ theme }) => theme.texts.little.fontWeight};
    line-height: ${({ theme }) => theme.texts.little.lineHeight};
  `,
  LittleBold: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.littleBold.fontSize};
    font-weight: ${({ theme }) => theme.texts.littleBold.fontWeight};
    line-height: ${({ theme }) => theme.texts.littleBold.lineHeight};
  `,
  Legal: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.legal.fontSize};
    font-weight: ${({ theme }) => theme.texts.legal.fontWeight};
    line-height: ${({ theme }) => theme.texts.legal.lineHeight};
  `,
  LegalBold: styled(GenericText)`
    font-size: ${({ theme }) => theme.texts.legalBold.fontSize};
    font-weight: ${({ theme }) => theme.texts.legalBold.fontWeight};
    line-height: ${({ theme }) => theme.texts.legalBold.lineHeight};
  `
}
