import React from 'react'
import styled from 'styled-components'

import { WagtailImage } from '@/components/blocks/Image'

import type { TeamMemberBlock } from '@/models/Blocks'

export const TeamMember = ({
  block,
  id
}: {
  block: TeamMemberBlock
  id?: string
}) => {
  if (!block) return null

  const {
    value: { options, fullName, role }
  } = block

  return (
    <div id={id}>
      <WagtailImage block={options[0]} />
      <TextSection.Wrapper>
        <TextSection.FullName>{fullName}</TextSection.FullName>
        <TextSection.Role>{role}</TextSection.Role>
      </TextSection.Wrapper>
    </div>
  )
}

const TextSection = {
  Wrapper: styled.div`
    text-align: center;
    margin: 0 auto;
    max-width: 258px;
    min-width: 258px;
    margin-top: 8px;
    p {
      margin: 0;
    }
  `,
  FullName: styled.p`
    font-size: ${({ theme }) => theme.texts.big?.fontSize};
    color: ${({ theme }) => theme.colors.secondary[100]};
  `,
  Role: styled.p`
    font-size: ${({ theme }) => theme.texts.normal.fontSize};
    color: ${({ theme }) => theme.colors.neutrals[900]};
  `
}
