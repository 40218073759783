import React from 'react'
import styled from 'styled-components'

import type { CoverImageBlock } from '@/models/Blocks'

export const CoverImage = ({
  block,
  id
}: {
  block: CoverImageBlock
  id?: string
}) => {
  if (!block) return null

  const { value } = block

  return <Wrapper backgroundImage={value.url} id={id} />
}

const Wrapper = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 75vh;
  margin-top: 80px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
