import React from 'react'
import styled, { useTheme } from 'styled-components'

import { JobApplicationType } from '@/utils/constants'
import { Text } from '@/components/commons/Typography'

export const JobApplication = ({
  applicationType,
  showDecorator = true
}: {
  applicationType: keyof typeof JobApplicationType
  showDecorator?: boolean
}) => {
  const { colors } = useTheme()
  if (!applicationType) return null

  const generateApplicationType = (
    applicationType: keyof typeof JobApplicationType
  ): JSX.Element => {
    switch (applicationType) {
      case JobApplicationType.open:
        return (
          <>
            <Text.SmallBold uppercase color={colors.secondary[400]}>
              open position
            </Text.SmallBold>
            {showDecorator && (
              <Text.SmallBold color={colors.secondary[400]}>| </Text.SmallBold>
            )}
          </>
        )
      case JobApplicationType.spontaneous:
        return (
          <>
            <Text.SmallBold uppercase color={colors.secondary[300]}>
              spontaneous application
            </Text.SmallBold>
            {showDecorator && (
              <Text.SmallBold color={colors.secondary[100]}>| </Text.SmallBold>
            )}
          </>
        )
      default:
        return (
          <>
            <Text.SmallBold uppercase color={colors.secondary[300]}>
              {applicationType}
            </Text.SmallBold>
            {showDecorator && (
              <Text.SmallBold color={colors.secondary[100]}>| </Text.SmallBold>
            )}
          </>
        )
    }
  }

  return <Wrapper>{generateApplicationType(applicationType)}</Wrapper>
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
