import { CodeBlock as CodeBlockReact, nord } from 'react-code-blocks'
import React from 'react'
import styled from 'styled-components'

import type { CodeBlock } from '@/models/Blocks'

export const Code = ({ block, id }: { block: CodeBlock; id?: string }) => {
  if (!block || !block.value) return null

  const { language, code, showLineNumbers } = block.value

  return (
    <Wrapper>
      <CodeBlockReact
        id={id}
        text={code}
        language={language}
        showLineNumbers={showLineNumbers}
        theme={nord}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > span {
    padding: 20px;
    border-radius: 20px !important;
  }
`
