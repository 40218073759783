import { useWindowSize } from 'usehooks-ts'
import React from 'react'
import styled from 'styled-components'

import { MultilineCard } from '@/components/blocks/MultilineCard'
import { BlockKeys, MultilineCardItemBlockKeys } from '@/utils/constants'
import { Slider } from '@/components/commons/Slider'
import { WagtailImage } from '@/components/blocks/Image'

import type { MultilineCardBlockItem } from '@/models/MultilineCardsListBlocks'
import type { MultilineCardsListBlock } from '@/models/Blocks'

export const MultilineCardsList = ({
  block,
  id,
  isSection
}: {
  block: MultilineCardsListBlock
  id?: string
  isSection?: boolean
}) => {
  const { width } = useWindowSize()

  if (!block || !block.value) return null

  const {
    value: { cards, isSliderOnMobile, background }
  } = block

  if (!cards) return null

  const generateCardsBlockByType = (
    block: MultilineCardBlockItem,
    id: string
  ): JSX.Element => {
    switch (block.type) {
      case MultilineCardItemBlockKeys.multilinecard:
        return <MultilineCard block={block} id={id} />
      case BlockKeys.image:
        return (
          <ImageWrapper className='keen-slider__slide'>
            <WagtailImage block={block} id={id} />
          </ImageWrapper>
        )
      default:
        return <p>Block type not supported</p>
    }
  }

  if (width < 769 && isSliderOnMobile) {
    return (
      <SliderWrapper background={background}>
        <Slider
          areDotsVisible
          initialSlide={0}
          sliderOptions={{
            mode: 'snap',
            slides: { origin: 'center', perView: 1.1, spacing: 30 }
          }}
        >
          {cards.map((card, i) => (
            <React.Fragment key={i}>
              {generateCardsBlockByType(card, `${id}-cards-item-${i + 1}`)}
            </React.Fragment>
          ))}
        </Slider>
      </SliderWrapper>
    )
  }

  return (
    <Container id={id} background={background} isCenter={!isSection}>
      {cards.map((card, i) => (
        <React.Fragment key={i}>
          {generateCardsBlockByType(card, `${id}-cards-item-${i + 1}`)}
        </React.Fragment>
      ))}
    </Container>
  )
}

const Container = styled.div<{ background: string; isCenter: boolean }>`
  ${({ background }) =>
    background &&
    `
      background: ${background};
    `}
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: flex;
    flex-wrap: wrap;
    ${({ isCenter }) =>
      isCenter &&
      `
      justify-content: center;
    `}
    gap: 36px;
    & > * {
      min-width: 350px;
      max-width: 350px;
    }
    padding: ${({ isCenter }) => (isCenter ? '110px 160px 0' : '0 160px 50px')};
  }
`

const SliderWrapper = styled.div<{ background: string }>`
  ${({ background }) =>
    background &&
    `
      background: ${background};
    `}
`

const ImageWrapper = styled.div``
