import React from 'react'
import styled, { useTheme } from 'styled-components'

import { EyebrowTitle } from '@/components/commons/EyebrowTitle'
import { GenericLink } from '@/components/commons/GenericLink'
import { H2 } from '@/components/commons/Typography'
import { LinkTypeValues } from '@/utils/constants'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { ImageLinkTextBlock } from '@/models/Blocks'

export const ImageLinkText = ({
  block,
  id
}: {
  block: ImageLinkTextBlock
  id?: string
}) => {
  const { colors } = useTheme()

  if (!block) return null

  const {
    value: { background, headline, image, link, linkType, text, title }
  } = block

  return (
    <Background background={background} id={id}>
      <Wrapper>
        <ImageWrapper>
          <RenditionImage image={image} />
        </ImageWrapper>
        <TextWrapper>
          {title && (
            <EyebrowTitle color={colors.secondary[300]} headline={headline}>
              <H2.Normal color={colors.secondary[100]}>{title}</H2.Normal>
            </EyebrowTitle>
          )}
          <StyledRichTextParser htmlContent={text} />
          <StyledGenericLink
            wagtailLink={link}
            role={linkType === LinkTypeValues.simple ? 'link' : 'button'}
            ui={linkType === LinkTypeValues.simple ? undefined : linkType}
            showDecorator
          />
        </TextWrapper>
      </Wrapper>
    </Background>
  )
}

const Background = styled.div<{ background: string | null }>`
  background: ${({ background, theme }) =>
    background || theme.colors.neutrals[0]};
  padding-bottom: 110px;
`

const Wrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 140px 160px 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 48px 24px;
    gap: 30px;
    border-radius: 20px;
    flex-direction: column-reverse;
    background: ${({ theme }) => theme.colors.neutrals[100]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledRichTextParser = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.neutrals[900]};
`

const StyledGenericLink = styled(GenericLink)<{ role: 'button' | 'link' }>`
  ${({ role, theme }) =>
    role === 'link' &&
    `
    color: ${theme.colors.secondary[200]};
  `}
  margin-top: 10px;
`
