import React from 'react'
import styled from 'styled-components'

import { Text } from '@/components/commons/Typography'
import { WagtailImage } from '@/components/blocks/Image'

import type { ArticleCategories } from '@/models/Utils'

export const ArticleCategory = ({
  category
}: {
  category: ArticleCategories
}) => {
  if (!category) return null

  const { options, color } = category
  return (
    <Wrapper>
      <WagtailImage block={options[0]} />
      <Text.SmallBold uppercase color={color}>
        {category.name}
      </Text.SmallBold>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
