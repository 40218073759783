import { chunk } from 'lodash'
import Marquee from 'react-fast-marquee'
import React from 'react'
import styled from 'styled-components'

import { BlockKeys, CarouselItemBlockKeys } from '@/utils/constants'
import { Client } from '@/components/blocks/Client'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { Technology } from '@/components/blocks/Technology'

import type { CarouselBlock } from '@/models/Blocks'
import type { CarouselBlockItems } from '@/models/CarouselBlocks'

export const Carousel = ({
  block,
  id,
  sectionBackground
}: {
  block: CarouselBlock
  id?: string
  sectionBackground?: string | null
}) => {
  if (!block) return null

  const {
    value: { rowsNumber, isSlider, items }
  } = block

  if (!items) return null

  const generateCarouselBlockByType = (
    block: CarouselBlockItems,
    id: string
  ): JSX.Element => {
    switch (block.type) {
      case CarouselItemBlockKeys.client:
        return <Client block={block} id={id} />
      case CarouselItemBlockKeys.technology:
        return <Technology block={block} id={id} />
      case BlockKeys.image:
        return <RenditionImage image={block.value} id={id} />
      default:
        return <p>Block type not supported</p>
    }
  }

  const Slides = ({
    items,
    rowId
  }: {
    items: CarouselBlockItems[]
    rowId: number
  }) => {
    return (
      <>
        {items.map((item, i) => (
          <React.Fragment key={i}>
            {generateCarouselBlockByType(
              item,
              `${id}-carousel-item-${rowId}-${i + 1}`
            )}
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <Wrapper id={id} sectionBackground={sectionBackground}>
      {chunk(items, Math.ceil(items.length / rowsNumber)).map((row, i) =>
        isSlider ? (
          <Marquee
            direction={i % 2 === 0 ? 'left' : 'right'}
            speed={40}
            key={i}
          >
            <Slides items={row} rowId={i + 1} />
          </Marquee>
        ) : (
          <TechnologiesList key={i}>
            <Slides items={row} rowId={i + 1} />
          </TechnologiesList>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ sectionBackground?: string | null }>`
  display: flex;
  gap: 80px;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;
  background-color: ${({ sectionBackground }) =>
    sectionBackground || 'transparent'};
  .marquee .child img {
    margin-right: 80px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    gap: 40px;
    padding-bottom: 50px;
  }
`

const TechnologiesList = styled.div`
  padding-left: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 0 20px;
  }
`
