import React from 'react'
import styled, { useTheme } from 'styled-components'

import { H4 } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { RichTextParser } from '@/components/commons/RichTextParser'

import type { SingleColumnCardBlock } from '@/models/SingleColumnCardsListBlocks'

export const SingleColumnCard = ({
  block,
  id
}: {
  block: SingleColumnCardBlock
  id?: string
}) => {
  const { colors } = useTheme()

  if (!block) return null

  const {
    value: { image, text, title }
  } = block

  return (
    <Wrapper id={id} className='keen-slider__slide'>
      <RenditionImage image={image} />
      <TextWrapper>
        {title && <H4.Normal color={colors.secondary[100]}>{title}</H4.Normal>}
        <StyledRichTextParser htmlContent={text} />
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 48px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 48px 24px;
    gap: 30px;
    border-radius: 20px;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.neutrals[100]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`

const StyledRichTextParser = styled(RichTextParser)`
  color: ${({ theme }) => theme.colors.neutrals[900]};
`
