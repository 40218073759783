import styled, { useTheme } from 'styled-components'
import React, { useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody
} from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'

import { H4, H5 } from '@/components/commons/Typography'
import { RenditionImage } from '@/components/commons/RenditionImage'
import { Slider } from '@/components/commons/Slider'

import type { AccordionBlock } from '@/models/Blocks'

const initialIndex = 1

export const Accordion = ({
  block,
  id
}: {
  block: AccordionBlock
  id: string
}) => {
  const { width } = useWindowSize()
  const { colors, breakpoint } = useTheme()
  const [index, setIndex] = useState(initialIndex)

  if (!block || !block.value) return null

  const { items, text } = block.value

  const style = {
    '--cui-accordion-color': colors.secondary[100],
    '--cui-accordion-bg': colors.neutrals[100],
    '--cui-accordion-active-color': colors.secondary[100],
    '--cui-accordion-active-bg': colors.neutrals[100],
    '--cui-accordion-btn-color': colors.secondary[100],
    '--cui-accordion-btn-padding-y': '42px',
    '--cui-accordion-btn-padding-x': '42px',
    '--cui-accordion-body-padding-y': '5px 42px',
    '--cui-accordion-body-padding-x': '42px'
  }

  return (
    <Wrapper>
      {width < breakpoint.lg ? (
        <Slider
          areArrowsVisible={false}
          areDotsVisible={true}
          sliderOptions={{
            loop: true,
            mode: 'snap',
            slides: { origin: 'center', perView: 1, spacing: 30 }
          }}
        >
          {items?.map((item, i) => (
            <Slide key={i} className='keen-slider__slide'>
              <RenditionImage image={item.image} />
              <TextSlide>
                <H5.Normal>{item.title}</H5.Normal>
                <p>{item.text}</p>
              </TextSlide>
            </Slide>
          ))}
        </Slider>
      ) : (
        <WrapperAccordion id={id}>
          <CAccordion
            style={style as React.CSSProperties}
            activeItemKey={initialIndex}
          >
            {items.map((item, i) => (
              <CAccordionItem
                key={i}
                itemKey={i + 1}
                onClick={() => setIndex(i + 1)}
              >
                <CAccordionHeader>
                  <H5.Normal>{item.title}</H5.Normal>
                </CAccordionHeader>
                <CAccordionBody>{item.text}</CAccordionBody>
              </CAccordionItem>
            ))}
          </CAccordion>
          <RenditionImage image={items[index - 1].image} />
        </WrapperAccordion>
      )}
      <Description>{text}</Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 160px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    padding: 0 30px;
  }
`

const WrapperAccordion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  .accordion {
    display: flex;
    row-gap: 18px;
    flex-direction: column;
    border: none !important;
    min-width: 380px;
  }
  .accordion-item,
  .accordion-button {
    border-radius: 20px;
    box-shadow: none !important;
    border: none !important;
    button[aria-expanded='false'] {
      pointer-events: none;
    }
  }
  .accordion-body {
    color: ${({ theme }) => theme.colors.neutrals[900]};
  }
  * {
    flex: 1;
  }
`
const Description = styled(H4.Normal)`
  text-align: center;
  margin-top: 90px;
  color: ${({ theme }) => theme.colors.secondary[100]};
`
const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    object-fit: contain;
    margin-bottom: 10px;
    height: 100%;
  }
`
const TextSlide = styled.div`
  background: ${({ theme }) => theme.colors.neutrals[100]};
  padding: 34px;
  border-radius: 20px;
  width: 100%;
`
