import React from 'react'
import styled from 'styled-components'

import type { ReactNode } from 'react'

type Props = {
  children: ReactNode[] | ReactNode
  color?: string
  className?: string
}

export const Tag = ({ children, className, color }: Props) => {
  return (
    <Wrapper color={color} className={className}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ color?: string }>`
  background: ${({ color, theme }) => color || theme.colors.secondary[200]};
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 50px;
  width: fit-content;
  :hover {
    cursor: default;
  }
`
