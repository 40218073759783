import React from 'react'
import Head from 'next/head'

import type { WagtailMeta } from '@/models/Pages'
import { SeoImageKeys } from '@/utils/constants'
import { useRouter } from 'next/router'

type Props = {
  baseUrl: string
  meta: WagtailMeta
}

export const Meta = ({ baseUrl, meta }: Props) => {
  const { locale, asPath } = useRouter()

  const websiteUrl = `${baseUrl}${asPath}`.replace(/(\?.*|#[^#]*)$/, '')

  const ogImage =
    meta.openGraph &&
    meta.openGraph.length > 0 &&
    meta.openGraph.find(e => e.type === SeoImageKeys.ogimage)
  const twitterImage =
    meta.openGraph &&
    meta.openGraph.length > 0 &&
    meta.openGraph.find(e => e.type === SeoImageKeys.twitterimage)

  return (
    <Head>
      <title>{meta.seoTitle}</title>
      <meta name='description' content={meta.searchDescription} key='desc' />
      <meta name='keywords' content={meta.seoKeywords} />
      <meta property='og:description' content={meta.searchDescription} />
      {ogImage && (
        <>
          <meta property='og:image' content={ogImage.value.url} />
          <meta property='og:image:url' content={ogImage.value.url} />
          <meta property='og:image:secure_url' content={ogImage.value.url} />
          <meta property='og:image:type' content='image/png' />
          <meta property='og:image:width' content={`${ogImage.value.width}`} />
          <meta
            property='og:image:height'
            content={`${ogImage.value.height}`}
          />
        </>
      )}
      <meta property='og:locale' content={locale} />
      <meta property='og:title' content={meta.seoTitle} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={websiteUrl} />
      {twitterImage && (
        <>
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='@20tab' />
          <meta name='twitter:description' content={meta.searchDescription} />
          <meta name='twitter:image' content={twitterImage.value.url} />
          <meta name='twitter:site' content='@20tab' />
          <meta name='twitter:title' content={meta.seoTitle} />
        </>
      )}
    </Head>
  )
}
